import { createSlice } from "@reduxjs/toolkit";
import { UserRole } from "../generated/graphql";

type ProfileReducer = {
  allowedRoles: UserRole[];
};

const initialState: ProfileReducer = {
  allowedRoles: [UserRole.SystemAdmin, UserRole.OrderModerator],
};

const { reducer: profileReducer, getInitialState: getProfileInitialState } =
  createSlice({
    name: "profile",
    initialState,
    reducers: {},
  });

export { profileReducer, getProfileInitialState };
